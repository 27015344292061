import { RouteRecordRaw } from 'vue-router';
import { individualBaseMeta, purchaseMeta, consoleBaseMeta } from '~/router/router-meta';
import { createMultipRouterView } from '~/utils/router-generate';

// 天威云购买
export const purchase: RouteRecordRaw[] = [
  // 天威诚信电子合同购买
  {
    path: 'VCertSignature',
    name: 'v-cert-sign-purchase',
    component: () => import('~/views/goods/ecology-cloud/v-cert-sign/Purchase.vue'),
    meta: purchaseMeta
  }
];

// 天威诚信订单
export const order: RouteRecordRaw[] = [
  {
    path: 'VCertCloud',
    name: 'v-cert-cloud-view',
    redirect: '/Individual/Order/EcologyCloud/VCertCloud/VCertSign',
    component: createMultipRouterView('grape-low-code-order-view'),
    meta: individualBaseMeta,
    menuProps: {
      name: '天威云',
      needExpend: true
    },
    children: [
      // 天威诚信电子合同订单
      {
        path: 'VCertSign',
        name: 'v-cert-sign-order',
        component: () => import('~/views/goods/ecology-cloud/v-cert-sign/OrderList.vue'),
        meta: individualBaseMeta,
        menuProps: {
          name: '天威诚信电子合同',
          show: true
        }
      }
    ]
  }
];

// 天威诚信控制台
export const consolePage: RouteRecordRaw[] = [
  // 天威诚信 电子合同
  {
    path: 'VCertSignatureConsole',
    name: 'v-cert-sign-console-view',
    redirect: '/Console/VCertSignatureConsole/Contract',
    component: () => import('~/views/goods/ecology-cloud/v-cert-sign/Console.vue'),
    meta: consoleBaseMeta,
    children: [
      // 合同管理
      {
        path: 'Contract',
        name: 'v-cert-sign-console-contract',
        component: () => import('~/views/goods/ecology-cloud/v-cert-sign/ContractManage.vue'),
        meta: consoleBaseMeta,
      },
      {
        path: 'Create',
        name: 'v-cert-sign-console-contract-create',
        component: () => import('~/views/goods/ecology-cloud/v-cert-sign/ContractCreate.vue'),
        meta: consoleBaseMeta
      },
      // 成员管理
      {
        path: 'Member',
        name: 'v-cert-sign-console-member',
        component: () => import('~/views/goods/ecology-cloud/v-cert-sign/MemberManage.vue'),
        meta: consoleBaseMeta
      },
      // 文件管理
      {
        path: 'File',
        name: 'v-cert-sign-console-file',
        component: () => import('~/views/goods/ecology-cloud/v-cert-sign/FileManage.vue'),
        meta: consoleBaseMeta
      }
    ]
  },
  // 天威诚信 电子合同 添加签署人
  {
    path: 'VCertAddSigner/:id',
    name: 'v-cert-add-signer',
    component: () => import('~/views/goods/ecology-cloud/v-cert-sign/AddSigner.vue'),
    meta: consoleBaseMeta
  }
];
