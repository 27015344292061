import { RouteRecordRaw } from 'vue-router';
import { individualBaseMeta, purchaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute, createMultipRouterView } from '~/utils/router-generate';


const api = () => import('~/api/product/encology-colud/holly-crm');
const tableConfig = () => import('~/views/goods/ecology-cloud/holly-crm/table.config');

export const purchase: RouteRecordRaw[] = [
  {
    path: 'IntelligentCloud',
    name: 'intelligent-cloud-purchase',
    component: () => import('~/views/goods/ecology-cloud/holly-crm/Purchase.vue'),
    meta: purchaseMeta
  },
  // 新增坐席/续费页面
  {
    path: 'IntelligentCloudRenew',
    name: 'intelligent-cloud-renew',
    component: () => import('~/views/goods/ecology-cloud/holly-crm/Renew.vue'),
    meta: purchaseMeta
  }

];

// 订单列表
export const order: RouteRecordRaw = {
  path: 'HollyCrm',
  name: 'holly-crm-order-list',
  component: createMultipRouterView('holly-crm-order-list'),
  redirect: '/Individual/Order/EcologyCloud/HollyCrm/IntelligentCloud',
  meta: individualBaseMeta,
  menuProps: {
    name: '合力亿捷',
    needExpend: true
  },
  children: [
    createGoodsBusinessRoute<'order'>({
      path: 'IntelligentCloud',
      name: 'intelligent-cloud-order-list',
      meta: individualBaseMeta,
      menuProps: { name: '智能云客服系统', show: true },
      component: 'order',
      props: {
        recordsApi: { path: api, property: 'getHollyOrderList' },
        columns: { path: tableConfig, property: 'orderColumns' },
        paymentRoute: row => {
          const { ptId, orderId, orderNum } = row;
          return `/IntelligentCloud?ptId=${ptId}&orderId=${orderId}&orderNum=${orderNum}`;
        },
        navigator: {
          list: [
            { text: '订单管理' },
            { text: '合力亿捷' },
            { text: '智能云客服系统' }
          ]
        },
      }
    })
  ]
};


// 续费列表
export const renewal: RouteRecordRaw = {
  path: 'HollyCrm',
  name: 'holly-crm-renew-list',
  redirect: '/Individual/Renew/EcologyCloud/HollyCrm/IntelligentCloud',
  component: createMultipRouterView('holly-crm-renew-list'),
  meta: individualBaseMeta,
  menuProps: {
    name: '合力亿捷',
    needExpend: true
  },
  children: [
    {
      path: 'IntelligentCloud',
      name: 'intelligent-cloud-renew-list',
      component: () => import('~/views/goods/ecology-cloud/holly-crm/RenewList.vue'),
      menuProps: {
        name: '智能云客服系统',
        show: true
      }
    }
  ]
};