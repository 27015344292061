import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta, individualBaseMeta } from '~/router/router-meta';
import { createMultipRouterView, createGoodsBusinessRoute } from '~/utils/router-generate';

// 订单、续费路由文件配置
const tableConfig = () => import('~/views/goods/ecology-cloud/cloud-dream/table.config');
// 接口文件配置
const api = () => import('~/api/product/encology-colud/cloud-dream');
// 常量文件配置
const constant = () => import('~/views/goods/ecology-cloud/cloud-dream/constant');
// 获取实例API文件
const intanceApi = () => import('~/api/product');

// 云·速成美站 购买流程路由
const CloudWebsitePurchaseRouterView: RouteRecordRaw = createGoodsBusinessRoute<'old_settlement'>({
  path: 'SpeededWebsite/Settlement/:orderId',
  name: 'speeded-website-settlement',
  meta: purchaseMeta,
  component: 'old_settlement',
  props: {
    orderRoute: '/Individual/Order/EcologyCloud/CloudDream/SpeededWebsite',
    paymentApi: { path: api, property: 'payCloudDreamOrder' },
    orderDetailJsonConfig: { path: constant, property: 'orderDetailProductConfig' },
    customDetailKeys: ['buyDuration'],
  }
});

// 云·企业官网 购买流程路由
const EnterpriseWbsitePurchaseRouterView: RouteRecordRaw = createGoodsBusinessRoute<'old_settlement'>({
  path: 'EnterpriseWbsite/Settlement/:orderId',
  name: 'enterprise-website-settlement',
  meta: purchaseMeta,
  component: 'old_settlement',
  props: {
    orderRoute: '/Individual/Order/EcologyCloud/CloudDream/EnterpriseWbsite',
    paymentApi: { path: api, property: 'payCloudDreamOrder' },
    orderDetailJsonConfig: { path: constant, property: 'orderDetailProductConfig' },
    customDetailKeys: ['buyDuration'],
  }
});


export const purchase: RouteRecordRaw[] = [
  {
    path: 'CloudDream',
    name: 'cloud-dream',
    children: [
    
      /* 新购 */
      {
        path: 'Purchase',
        name: 'speeded-website-purchase-new',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/cloud-dream/Purchase.vue'),
      },
      /* 续费 */
      {
        path: 'Renwal',
        name: 'cloud-dream-purchase-renwal',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/cloud-dream/Renew.vue'),
      },
      CloudWebsitePurchaseRouterView,
      EnterpriseWbsitePurchaseRouterView]
  }
];


export const order: RouteRecordRaw = {
  path: 'CloudDream',
  name: 'cloud-dream-order',
  component: createMultipRouterView('cloud-dream-order'),
  redirect: '/Individual/Order/EcologyCloud/CloudDream/SpeededWebsite',
  meta: individualBaseMeta,
  menuProps: {
    name: '云梦建站',
    needExpend: true
  },
  children: [
    createGoodsBusinessRoute<'order'>({
      path: 'SpeededWebsite',
      name: 'speeded-website-order',
      meta: individualBaseMeta,
      menuProps: { name: '云·速成美站', show: true },
      component: 'order',
      props: {
        filterTable: {
          cache: true
        },
        recordsApi: { path: api, property: 'getSpeededWebsiteList' },
        jsonLabel: { path: constant, property: 'orderDetailProductConfig' },
        paymentRoute: ({ orderId }) => `/CloudDream/SpeededWebsite/Settlement/${orderId}`,
        navigator: {
          list: [
            { text: '订单管理' },
            { text: '云梦建站' },
            { text: '云·速成美站' }
          ]
        },
      }
    }),
    createGoodsBusinessRoute<'order'>({
      path: 'EnterpriseWbsite',
      name: 'enterprise-website-order',
      meta: individualBaseMeta,
      menuProps: { name: '云·企业官网', show: true },
      component: 'order',
      props: {
        filterTable: {
          cache: true
        },
        recordsApi: { path: api, property: 'getEnterpriseWbsiteOrderList' },
        jsonLabel: { path: constant, property: 'orderDetailProductConfig' },
        paymentRoute: ({ orderId }) => `/CloudDream/EnterpriseWbsite/Settlement/${orderId}`,
        navigator: {
          list: [
            { text: '订单管理' },
            { text: '云梦建站' },
            { text: '云·企业官网' }
          ]
        },
      }
    }),
  ]
};

export const renewal = {
  path: 'CloudDream',
  name: 'cloud-dream-instance',
  component: createMultipRouterView('cloud-dream-instance'),
  redirect: '/Individual/Renwal/EcologyCloud/CloudDream/SpeededWebsite',
  meta: individualBaseMeta,
  menuProps: {
    name: '云梦建站',
    needExpend: true
  },
  children: [
    createGoodsBusinessRoute<'renewal'>({
      path: 'SpeededWebsite',
      name: 'speeded-website-instance',
      meta: individualBaseMeta,
      menuProps: { name: '云·速成美站', show: true },
      component: 'renewal',
      props: {
        filterTable: { cache: true },
        recordsApi: { path: intanceApi, property: 'getGoodsExample' },
        columns: { path: tableConfig, property: 'speededColumns' },
        navigator: {
          list: [
            { text: '续费管理' },
            { text: '云梦建站' },
            { text: '云·速成美站' }
          ]
        }
      }
    }),
    createGoodsBusinessRoute<'renewal'>({
      path: 'EnterpriseWbsite',
      name: 'enterprise-website-instance',
      meta: individualBaseMeta,
      menuProps: { name: '云·企业官网', show: true },
      component: 'renewal',
      props: {
        filterTable: { cache: true },
        recordsApi: { path: intanceApi, property: 'getGoodsExample' },
        columns: { path: tableConfig, property: 'enterpriseColumns' },
        navigator: {
          list: [
            { text: '续费管理' },
            { text: '云梦建站' },
            { text: '云·企业官网' }
          ]
        }
      }
    }),
  ]
};
