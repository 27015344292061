import { RouteRecordRaw } from 'vue-router';
import { individualBaseMeta, purchaseMeta } from '~/router/router-meta';
import { createMultipRouterView } from '~/utils/router-generate';

// 葡萄城产品
export const purchase: RouteRecordRaw[] = [
  // 葡萄城购买页面
  {
    path: 'GrapeLowCode',
    name: 'grape-low-code-purchase',
    component: () => import('~/views/goods/ecology-cloud/grape-low-code/Purchase.vue'),
    meta: purchaseMeta
  },
  // 葡萄城 增配/续费页面
  {
    path: 'GrapeLowCodeRenew',
    name: 'grape-low-code-renew',
    component: () => import('~/views/goods/ecology-cloud/grape-low-code/Renew.vue'),
    meta: purchaseMeta
  }
];

// 葡萄城订单列表
export const order: RouteRecordRaw = {
  path: 'GrapeLowCode',
  name: 'grape-low-code-order-view',
  redirect: '/Individual/Order/EcologyCloud/GrapeLowCode/List',
  component: createMultipRouterView('grape-low-code-order-view'),
  meta: individualBaseMeta,
  menuProps: {
    name: '葡萄城',
    needExpend: true
  },
  children: [
    {
      path: 'List',
      name: 'grape-low-code-order-list',
      component: () => import('~/views/goods/ecology-cloud/grape-low-code/OrderList.vue'),
      meta: individualBaseMeta,
      menuProps: {
        name: '活字格',
        show: true
      }
    }
  ]
};
// 葡萄城续费列表
export const renewal: RouteRecordRaw = {
  path: 'GrapeLowCode',
  name: 'grape-low-code-renew-view',
  redirect: '/Individual/Renew/EcologyCloud/GrapeLowCode/List',
  component: createMultipRouterView('grape-low-code-renew-view'),
  meta: individualBaseMeta,
  menuProps: {
    name: '葡萄城',
    needExpend: true
  },
  children: [
    {
      path: 'List',
      name: 'grape-low-code-renew-list',
      component: () => import('~/views/goods/ecology-cloud/grape-low-code/RenewList.vue'),
      menuProps: {
        name: '活字格',
        show: true
      }
    }
  ]
};