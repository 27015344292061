import { RouteRecordRaw } from 'vue-router';
import { individualBaseMeta, purchaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/router-generate';

const api = () => import('~/api/product/encology-colud/cloud-box');
const constant = () => import('~/views/goods/ecology-cloud/cloud-box/constant');

export const purchase: RouteRecordRaw[] = [
  {
    path: 'CloudBox',
    name: 'cloud-box-purchase',
    meta: purchaseMeta,
    redirect: '/CloudBox/Purchase',
    children: [
      // 新购
      {
        path: 'Purchase',
        name: 'cloud-box-purchase-new',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/cloud-box/Purchase.vue'),
      },
      // 续费
      {
        path: 'Renewal/:orderId',
        name: 'cloud-box-purchase-renewal',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/cloud-box/Renewal.vue'),
      },
      // 结算
      createGoodsBusinessRoute<'old_settlement'>({
        path: 'Settlement/:orderId',
        name: 'cloud-box-settlement',
        meta: purchaseMeta,
        component: 'old_settlement',
        props: {
          orderRoute: '/Individual/Order/EcologyCloud/CloudBox',
          paymentApi: { path: api, property: 'payUtilOrder' },
          orderDetailJsonConfig: { path: constant, property: 'CloudBoxDetailJSON' },
        }
      })
    ]
  },
];

export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'CloudBox',
  name: 'cloud-box-order',
  meta: individualBaseMeta,
  menuProps: { name: '云盒', show: true },
  component: 'order',
  props: {
    filterTable: {
      cache: true
    },
    recordsApi: { path: api, property: 'getOrderList' },
    jsonLabel: { path: constant, property: 'CloudBoxDetailJSON' },
    paymentRoute: ({ orderId }) => `/CloudBox/Settlement/${orderId}`,
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '云盒' }
      ]
    },
  },
});

export const renewal: RouteRecordRaw = createGoodsBusinessRoute<'renewal'>({
  path: 'CloudBox',
  name: 'cloud-box-renewal',
  meta: individualBaseMeta,
  menuProps: { name: '云盒', show: true },
  component: 'renewal',
  props: {
    recordsApi: {
      path: () => import('~/api/product'),
      property: 'getGoodsExample'
    },
    columns: { path: constant, property: 'CloudBoxRenwalColumns' },
    navigator: {
      list: [
        { text: '续费管理' },
        { text: '云盒' },
      ]
    }
  }
});