import { RouteRecordRaw, RouteComponent } from 'vue-router';
import { GoodsType } from '~/constant/goods-enum';
import { individualBaseMeta, purchaseMeta } from '~/router/router-meta';

type RouteBaseDataType = {
  path: string,
  name: string,
  title: string,
  props: {
    types: number[],
    // 产品类型
    productType: number,
    // 购买数量配置
    showBuyNum?: boolean,
    // 购买时长配置
    durationData?: number[]
  }
}
const routeBaseData: RouteBaseDataType[] = [
  {
    path: 'AnHeng_FortressMachine',
    name: 'an-heng-fortress-machine',
    title: '堡垒机',
    props: {
      types: [0],
      productType: GoodsType.AH_BLJ,
    }
  },
  {
    path: 'AnHeng_DatabaseAudit',
    name: 'an-heng-database-audit',
    title: '数据库审计',
    props: {
      types: [0, 1],
      productType: GoodsType.AH_DATAAU,
      showBuyNum: true
    }
  },
  {
    path: 'AnHeng_Firewall',
    name: 'an-heng-fire-wall',
    title: '防火墙',
    props: {
      types: [0],
      productType: GoodsType.AH_FHQ,
    }
  },
  {
    path: 'AnHeng_EqualInsurance',
    name: 'an-heng-equal-insurance',
    title: '等保合规解决方案',
    props: {
      types: [2],
      productType: GoodsType.AH_DB,
    }
  },
  {
    path: 'AnHeng_VulnerabilityScanning',
    name: 'an-heng-vulnerability-scanning',
    title: '漏洞扫描',
    props: {
      types: [0],
      productType: GoodsType.AH_LDSM,
      durationData: [12]
    }
  },
  {
    path: 'AnHeng_AttackWarningeAPT',
    name: 'an-heng-attack-warning-apt',
    title: 'APT攻击预警服务',
    props: {
      types: [0],
      productType: GoodsType.AH_GJYJ,
    }
  },
  {
    path: 'AnHeng_HostSecurity',
    name: 'an-heng-host-secruity',
    title: '主机安全',
    props: {
      types: [0, 1],
      productType: GoodsType.AH_ZJAQ,
    }
  },
  {
    path: 'AnHeng_XuanwuShield',
    name: 'an-heng-xuan-wu-shield',
    title: '玄武盾云防护服务',
    props: {
      types: [0, 1],
      productType: GoodsType.AH_XUANWU,
      showBuyNum: true
    }
  },
  {
    path: 'AnHeng_LogAudit',
    name: 'an-heng-log-audit',
    title: '日志审计',
    props: {
      types: [0, 1],
      productType: GoodsType.AH_RZSJ,
      showBuyNum: true
    }
  },
  {
    path: 'AnHeng_WebsiteMonitoring',
    name: 'an-heng-website-monitoring',
    title: '网站监测',
    props: {
      types: [0, 1],
      productType: GoodsType.AH_WZJC,
      showBuyNum: true
    }
  }
];

// 创建新购路由
const Purchase = () => import('~/views/goods/ecology-cloud/an-heng-cloud/Purchase.vue');
const HostSecurityPurchase = () => import('~/views/goods/ecology-cloud/an-heng-cloud/HostSecurity.vue');
const createPurchaseRoutes = () => {
  return routeBaseData.map(item => {
    const { path, name, props } = item;
    return {
      path,
      name,
      props: {
        ...props,
        orderRoute: `/Individual/Order/EcologyCloud/AnHeng/${path}`
      },
      meta: purchaseMeta,
      component: props.productType === GoodsType.AH_ZJAQ ? HostSecurityPurchase : Purchase
    };
  });
};

// 创建续费路由
const Renew = () => import('~/views/goods/ecology-cloud/an-heng-cloud/Renew.vue');
const HostSecurityRenew = () => import('~/views/goods/ecology-cloud/an-heng-cloud/HostSecurityRenew.vue');
const createRenewRoutes = () => {
  return routeBaseData.map(item => {
    const { path, name, title, props } = item;
    const { showBuyNum, productType, types } = props;
    return {
      path: `/Renew/${path}`,
      name: `${name}-renew`,
      props: {
        productName: title,
        productType,
        showBuyNum,
        types,
        orderRoute: `/Individual/Order/EcologyCloud/AnHeng/${path}`
      },
      meta: purchaseMeta,
      component: props.productType === GoodsType.AH_ZJAQ ? HostSecurityRenew : Renew
    };
  });
};

// 获取订单/续费路由列表
const getOrderAndRenewRoutes = (
  nameType: string,
  file: () => Promise<RouteComponent>,
  navigatorCategory: string
) => {
  return routeBaseData.map(item => {
    const { path, name, title, props } = item;
    const { productType } = props;
    return {
      path,
      name: `${name}-${nameType}-list`,
      component: file,
      props: {
        productType,
        routePath: path,
        navigator: [
          { text: navigatorCategory },
          { text: '生态云' },
          { text: '安恒云' },
          { text: title }
        ]
      },
      meta: individualBaseMeta,
      menuProps: {
        name: title,
        show: true
      }
    };
  });
};

// 新购
export const purchase: RouteRecordRaw[] = [
  ...createPurchaseRoutes(),
  ...createRenewRoutes()
];

// 订单列表
const OrderList = () => import('~/views/goods/ecology-cloud/an-heng-cloud/OrderList.vue');
export const order: RouteRecordRaw[] = [
  {
    path: 'AnHeng',
    name: 'an-heng-order-view',
    redirect: '/Individual/Order/EcologyCloud/AnHeng/AnHeng_FortressMachine',
    meta: individualBaseMeta,
    menuProps: {
      name: '安恒云',
      needExpend: true
    },
    children: getOrderAndRenewRoutes('order', OrderList, '订单管理')
  }
];

// 续费列表
const RenewList = () => import('~/views/goods/ecology-cloud/an-heng-cloud/RenewList.vue');
export const renewal: RouteRecordRaw[] = [
  {
    path: 'AnHeng',
    name: 'an-heng-renew-view',
    redirect: '/Individual/Renew/EcologyCloud/AnHeng/AnHeng_FortressMachine',
    meta: individualBaseMeta,
    menuProps: {
      name: '安恒云',
      needExpend: true,
    },
    children: getOrderAndRenewRoutes('renew', RenewList, '续费管理')
  }
];