import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
  type AxiosError,
  type AxiosHeaders
} from 'axios';
import { httpStore } from '~/stores/http';
import { globalStore } from '~/stores/global';
import { customStorage } from '~/utils/storage';
import { nanoid } from 'nanoid';
import { StorageKeys } from '~/constant/storage-keys-enum';
import { localUserToken } from '~/models/user/index';
import { ElMessage } from 'element-plus';
import { loginBlackListRoute } from '~/constant/auth-blacklist';
import router from '~/router';
import { clearUserInfo } from '~/stores/user/user-store-util';

// 初始化配置参数类型
interface HttpInitConfigType extends HttpConfigType {
  interceptors?: HttpInterceptor
}
// 配置参数类型
interface HttpConfigType extends AxiosRequestConfig {
  // 接口超时重试次数
  retry?: number
  // 接口超时重试计数
  retryCount?: number
  // 接口超时重试时间
  retryDelay?: number
  // 接口是否可以被关闭
  canBeCancel?: boolean
  // 接口是否需要登陆
  isNeedLogin?: boolean
  // 接口唯一id
  requestUUID?: string
  // 报错时是否需要提示
  needErrorReport?: boolean
  // 是否需要返回深层data数据
  needDeepData?: boolean
  // 是否需要返回headers
  needHeaders?: boolean
  // 是否需要弹框警告
  notReport?: boolean
}
type ConfigType = HttpConfigType & { headers: AxiosHeaders };

// 返回参数类型
export interface HttpResponseType extends AxiosResponse {
  config: ConfigType
}

// 自定义拦截器类型
export interface HttpInterceptor {
  requestInterceptor?: (config: ConfigType) => ConfigType
  requestErrorInterceptor?: (err: any) => any
  responseInterceptor?: (response: HttpResponseType) => HttpResponseType
  responseErrorInterceptor?: (err: any) => any
}

// 错误响应拦截扩展数据类型
interface AxiosErrorExtraData {
  // 错误信息
  error_description?: string;
  // 错误码
  code?: number;
  // 错误信息
  msg?: string;
}

// 错误响应拦截类型
interface ResponseErrorType extends AxiosError<AxiosErrorExtraData> {
  config: ConfigType
}

class Http {
  public instance: AxiosInstance;
  constructor (config: HttpInitConfigType) {
    // 创建axios实例
    this.instance = axios.create(config);
    // 获取额外配置拦截器
    const { interceptors: extraInterceptors } = config;
    // 全局拦截
    const { interceptors: instanceInterceptors } = this.instance;
    const { request, response } = instanceInterceptors;
    // 配置额外拦截器
    if (extraInterceptors) {
      const {
        requestInterceptor,
        requestErrorInterceptor,
      } = extraInterceptors;
      // 配置请求拦截
      if (requestInterceptor || requestErrorInterceptor) {
        request.use(requestInterceptor || null, requestErrorInterceptor || null);
      }
    }
    // 全局请求拦截
    request.use((config: ConfigType) => {
      // 获取tencentId
      const tencentId = globalStore().tenantId;
      // 获取token
      const token = customStorage.getItem<localUserToken>(StorageKeys.TOKEN);
      // 向headers中添加tenant-id
      if (tencentId) {
        config.headers['Tenant-Id'] = tencentId;
      }
      // 向headers中添加blade-auth
      if (token && typeof token !== 'boolean') {
        config.headers['Blade-Auth'] = `bearer ${token.value.access_token}`;
      }
      // 判断接口是否可以终止请求
      if (config.canBeCancel) {
        // 生成终止请求实例
        const abort = new AbortController();
        // 生成唯一id
        const uuid = nanoid();
        // 记录唯一id
        config.requestUUID = uuid;
        // 记录唯一id 及 终止请求实例
        const { abortRequestList } = httpStore();
        abortRequestList.set(uuid, abort);
        // 终止请求标记
        config.signal = abort.signal;
      }
      if (httpStore().logging) {
        return Promise.reject({ config, code: 'ERR_LOGGING', message: '登录中' });
      }
      // 判断接口是否需要登录
      if (config.isNeedLogin && !token && !httpStore().logging) {
        return this.goLoginPage({ config, code: 'ERR_TOKEN_NOT_EXIST', message: '请重新登录' });
      }
      // 返回请求配置参数
      return config;
    });
    // 全局响应拦截
    response.use((response: HttpResponseType) => {
      const { config } = response;
      // 判断是否为可终止接口
      if (config.canBeCancel && config.signal && config.requestUUID) {
        // 删除abort列表中缓存的abort实例
        const { abortRequestList } = httpStore();
        abortRequestList.delete(config.requestUUID);
      }
      // 判断是否有外部配置的响应拦截器 若有响应拦截 则返回响应拦截器
      if (extraInterceptors?.responseInterceptor) {
        return extraInterceptors.responseInterceptor(response);
      }
      return response;
    }, (err: ResponseErrorType) => {
      const { config } = err;
      // 判断接口是否可以被关闭
      if (config.canBeCancel && config.requestUUID) {
        // 删除abort列表中缓存的abort实例
        const { abortRequestList } = httpStore();
        abortRequestList.delete(config.requestUUID);
      }
      // 响应失败拦截
      // 若当前接口需要登陆
      const { message, response } = err;
      const { code, error_description: errDesc } = response ? response.data : { error_description: '', code: 200 };
      // 若当前接口超时
      if (message.indexOf('timeout') >= 0 || (errDesc && errDesc.indexOf('Read timed out') >= 0)) {
        if (config) {
          if (
            typeof config.retry === 'number'
            && typeof config.retryCount === 'number'
            && typeof config.retryDelay === 'number'
          ) {
            // 接口重试调用
            if (config.retryCount < config.retry) {
              // 计数器加1
              config.retryCount += 1;
              // 按retryDelay时长分次调用超时接口
              return new Promise(resolve => {
                setTimeout(() => resolve(true), config.retryDelay);
              }).then(() => {
                return this.instance(config);
              });
            }
            // 重试到达上限 弹框警告
            if (!config.notReport) {
              ElMessage.error('当前网络不稳定，请稍后再试');
            }
          }
        }
        return Promise.reject(err);
      }
      // 接口鉴权
      if (code === 401) {
        return this.goLoginPage({ ...err, code: 'ERR_TOKEN_EXPIRE', message: '登录态过期, 请重新登录' });
      }
      // 弹框警告
      if (!config.notReport && (response?.data.msg || response?.data.error_description)) {
        ElMessage.error(response.data.msg || response.data.error_description);
      }
      return Promise.reject(err);
    });
  }

  goLoginPage (err: ResponseErrorType | { config: HttpConfigType, message: string, code: string }) {
    if (err.code === 'ERR_TOKEN_NOT_EXIST' || err.code === 'ERR_TOKEN_EXPIRE') {
      ElMessage.warning(err.message);
    }
    const { fullPath } = router.currentRoute.value;
    const path = loginBlackListRoute.includes(fullPath) ? undefined : window.btoa(fullPath);
    const { changeLoggingValue } = httpStore();
    // 将登录中状态改为true
    changeLoggingValue(true);
    // 关闭所有在调用的接口
    httpStore().cancelRequest();
    // 等待100毫秒 等待后续请求进入
    setTimeout(() => {
      clearUserInfo();
      // 跳转到登录页面
      router.replace({
        path: '/Login',
        query: { p: path }
      });
      // 将登录中状态改为false
      changeLoggingValue(false);
    }, 100);
    return Promise.reject(err);
  }

  request<T> (config: HttpConfigType): Promise<T> {
    return new Promise((resolve, reject) => {
      this.instance.request<T, T>(config).then(res => resolve(res)).catch(err => reject(err));
    });
  }

  get<T> (config: HttpConfigType): Promise<T> {
    return this.request<T>({ ...config, method: 'GET' });
  }
  post<T> (config: HttpConfigType): Promise<T> {
    return this.request<T>({ ...config, method: 'POST' });
  }
  delete<T> (config: HttpConfigType): Promise<T> {
    return this.request<T>({ ...config, method: 'DELETE' });
  }
  update<T> (config: HttpConfigType): Promise<T> {
    return this.request<T>({ ...config, method: 'PUT' });
  }
}

export default Http;
