import { RouteRecordRaw } from 'vue-router';
import {
  GoodsRouteExports,
  MultipleGoods,
  ProductRouteType,
  routeHandler
} from '~/utils/router-generate';

// 产品路由
const GoodsRoutes: RouteRecordRaw[] = [
  {
    path: '/goods',
    name: 'goods_detail',
    component: () => import('~/views/goods/goods-info/GoodsInfo.vue'),
    meta: {
      headerOpacity: false,
      requireAuth: false,
      notSticky: true,
    }
  }
];

// 订单路由
const OrderRoutes: RouteRecordRaw[] = [];

// 续费路由
const RenewalRoutes: RouteRecordRaw[] = [];

// 控制台路由
const ConsoleRoutes: RouteRecordRaw[] = [];

// 多产品路由配置
const multipleRoutesGoods = new Map<string, MultipleGoods>([
  [
    'ecology-cloud',
    { menuProps: { name: '生态云', needExpend: true, show: true } }
  ]
]);

// 获取除goods-routes/index.ts以外的所有路由文件
const goodsRoutesFiles = import.meta.glob(
  '~/router/goods-routes/*/*.ts',
  { eager: true }
) as Record<string, GoodsRouteExports>;

// 插入类型
const routePushMap = new Map<ProductRouteType, (route: RouteRecordRaw[]) => void>([
  // 购买
  ['purchase', route => GoodsRoutes.push(...route)],
  // 订单
  ['order', route => OrderRoutes.push(...route)],
  // 续费
  ['renewal', route => RenewalRoutes.push(...route)],
  // 控制台
  ['consolePage', route => ConsoleRoutes.push(...route)]
]);

// 将文件执行module导入获取module对象
for (const key in goodsRoutesFiles) {
  const fileModule = goodsRoutesFiles[key] as object;
  // 获取所有类型的产品路由
  const productRoutes = Object.entries(fileModule);
  const multipRoute = routeHandler({
    key,
    routeMap: multipleRoutesGoods,
    exportRoutes: goodsRoutesFiles
  });
  // 单个文件产品路由直接插入
  if (!multipRoute) {
    // 按类型进行填充
    for (let i = 0; i < productRoutes.length; i++) {
      // 扁平化
      const routes = productRoutes[i].flat(Infinity);
      // 获取类型
      const type = routes.shift() as ProductRouteType;
      // 插入到指定路由集合下
      routePushMap.get(type)?.call(null, routes);
    }
  }
}

// 遍历多产品路由配置
multipleRoutesGoods.forEach(({ route }) => {
  if (route) {
    const { order, renewal, consolePage, purchase } = route;
    if (purchase) {
      GoodsRoutes.push(...purchase);
    }
    if (order) {
      OrderRoutes.push(order);
    }
    if (renewal) {
      RenewalRoutes.push(renewal);
    }
    if (consolePage) {
      ConsoleRoutes.push(...consolePage);
    }
  }
});

export {
  GoodsRoutes,
  OrderRoutes,
  RenewalRoutes,
  ConsoleRoutes,
  multipleRoutesGoods
};