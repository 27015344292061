import { RouteRecordRaw } from 'vue-router';
import { consoleBaseMeta, individualBaseMeta, purchaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/router-generate';

// 常量文件
const constant = () => import('~/views/goods/ecology-cloud/enterprise-cloud-disk/constant');
// 接口文件
const api = () => import('~/api/product/encology-colud/enterprice-cloud-disk');

export const consolePage: RouteRecordRaw = {
  path: 'EnterpriceCloudDisk',
  name: 'enterprice-cloud-disk-console',
  component: () => import('~/views/goods/ecology-cloud/enterprise-cloud-disk/Console.vue'),
  meta: consoleBaseMeta
};

export const purchase: RouteRecordRaw = {
  path: 'EnterpriceCloudDisk',
  name: 'enterprice-cloud-disk-purchase',
  meta: purchaseMeta,
  children: [
    {
      path: 'Purchase',
      name: 'enterprice-cloud-purchase-new',
      component: () => import('~/views/goods/ecology-cloud/enterprise-cloud-disk/Purchase.vue'),
      meta: purchaseMeta
    },
    createGoodsBusinessRoute<'old_settlement'>({
      path: 'Settlement/:orderId',
      name: 'enterprice-cloud-settlement',
      meta: purchaseMeta,
      component: 'old_settlement',
      props: {
        orderRoute: '/Individual/Order/EcologyCloud/EnterpriceCloudDisk',
        paymentApi: { path: api, property: 'settlementOrder' },
        header: { path: constant, property: 'EnterpriceBanner' },
        orderDetailJsonConfig: { path: constant, property: 'enterpriceDiskJSONLabel' },
      }
    }),
  ]
};

export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'EnterpriceCloudDisk',
  name: 'enterprice-cloud-disk-order',
  meta: individualBaseMeta,
  menuProps: { name: '企业云盘', show: true },
  component: 'order',
  props: {
    // 导航
    navigator: { list: [{ text: '订单管理' }, { text: '企业云盘' }] },
    // 请求接口
    recordsApi: { path: api, property: 'fetchOrders' },
    // 订单详情
    jsonLabel: { path: constant, property: 'enterpriceDiskJSONLabel' },
    // 支付路由
    paymentRoute: ({ orderId }) => `/EnterpriceCloudDisk/Settlement/${orderId}`
  }
});