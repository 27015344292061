import { RouteRecordRaw } from 'vue-router';
import { individualBaseMeta, purchaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/router-generate';

const api = () => import('~/api/product/encology-colud/shadow-less');
const constant = () => import('~/views/goods/ecology-cloud/shadow-less/constant');
export const purchase: RouteRecordRaw[] = [
  {
    path: 'ShadowLess',
    name: 'shadow-less-purchase',
    meta: purchaseMeta,
    children: [
      // 新购
      {
        path: 'Purchase',
        name: 'shadow-less-purchase-new',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/shadow-less/Purchase.vue'),
      },
      // 续费
      {
        path: 'Renewal/:instanceInfo',
        name: 'shadow-less-purchase-renewal',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/shadow-less/Renewal.vue'),
      },
      // 结算
      createGoodsBusinessRoute<'old_settlement'>({
        path: 'Settlement/:orderId',
        name: 'shadow-less-settlement',
        meta: purchaseMeta,
        component: 'old_settlement',
        props: {
          orderRoute: '/Individual/Order/EcologyCloud/ShadowLess',
          paymentApi: { path: api, property: 'SettlementOrder' },
          orderDetailJsonConfig: { path: constant, property: 'shadowLessJsonLabel' },
        }
      })
    ]
  },
];


export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'ShadowLess',
  name: 'shadow-less-order',
  meta: individualBaseMeta,
  menuProps: { name: '无影云桌面', show: true },
  component: 'order',
  props: {
    filterTable: {
      cache: true
    },
    recordsApi: { path: api, property: 'getOrderList' },
    jsonLabel: { path: constant, property: 'shadowLessJsonLabel' },
    paymentRoute: ({ orderId }) => `/ShadowLess/Settlement/${orderId}`,
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '无影云桌面' }
      ]
    },
  }
});


export const renewal: RouteRecordRaw = createGoodsBusinessRoute<'renewal'>({
  path: 'ShadowLess',
  name: 'shadow-less-renewal',
  meta: individualBaseMeta,
  menuProps: { name: '无影云桌面', show: true },
  component: 'renewal',
  props: {
    recordsApi: {
      path: () => import('~/api/product'),
      property: 'getGoodsExample'
    },
    columns: { path: constant, property: 'shadowLessRenwalColumns' },
    navigator: {
      list: [
        { text: '续费管理' },
        { text: '无影云桌面' },
      ]
    }
  }
});