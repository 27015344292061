import qs from 'qs';
import { APIRequest } from '~/api/config';
import {
  BannerExpend,
  BannerResult,
  EAdvantage,
  HomeConfigData,
  HomeSolveRecord,
} from '~/models/essential/home-config';
import { ActivityModel } from '~/models/essential/activity';
import { SolutionParams, SolutionRecord, SolutionRecords } from '~/models/essential/solution-config';
import { ExportParams } from '~/models/base-types';
import { CompanyResultType } from '~/models/essential/company-introduction-config';
import { Dictionary } from '~/models/response-type';
import { AxiosProgressEvent } from 'axios';

const API_GLOBAL = {
  // 获取网站基础配置
  getSiteConfig (tenantId: string) {
    return APIRequest.get<HomeConfigData>({
      url: 'blade-vstec/exhomeconfig/getByTenantId',
      params: { page: 1, size: 100, tenantId },
      isNeedLogin: false
    });
  },
  // 获取banner
  getHomeBanner (tenantId: string) {
    return APIRequest.get<BannerResult>({
      url: 'blade-vstec/exhomerotation/page',
      params: { page: 1, size: 7, tenantId },
      isNeedLogin: false
    });
  },
  // 获取banner下方活动
  getHomeBannerActivity (tenantId: string) {
    return APIRequest.get<BannerExpend>({
      url: 'blade-vstec/exrotationexpand/page',
      params: { page: 1, size: 7, tenantId },
      isNeedLogin: false
    });
  },
  // 获取首页解决方案
  getHomeSolve (tenantId: string) {
    return APIRequest.get<HomeSolveRecord[]>({
      url: 'blade-vstec/exhomesolvefa/homeShow',
      params: { tenantId },
      isNeedLogin: false,
      canBeCancel: false,
    });
  },
  // 云产品e优势
  getHomeAdvantage (tenantId: string) {
    return APIRequest.get<EAdvantage>({
      url: 'blade-vstec/exproductadvantage/page',
      params: { page: 1, size: 6, tenantId },
      isNeedLogin: false
    });
  },
  // 获取解决方案列表
  getSolutionMap (params: SolutionParams) {
    return APIRequest.get<SolutionRecords>({
      url: 'blade-vstec/exsolveprogramme/page',
      params,
      isNeedLogin: false
    });
  },
  // 根据id获取解决方案详情
  getSolutionInfo (id: number) {
    return APIRequest.get<SolutionRecord>({
      url: `blade-vstec/exsolveprogramme/${id}`,
      isNeedLogin: false
    });
  },
  // 导出excel数据
  exportExcel <T = any>(params: ExportParams<T>) {
    return APIRequest.get<BlobPart>({
      url: `blade-vstec/dtorder/exportExcelData?${qs.stringify(params)}`,
      responseType: 'blob'
    });
  },
  // 根据域名获取对应的租户id
  getTenantId () {
    // 本地启动时 使用配置的开发域名 否则使用当前浏览器域名
    const { VITE_ONLINE, VITE_DOMAIN } = import.meta.env;
    const domain = VITE_ONLINE ? location.hostname : VITE_DOMAIN;
    return APIRequest.get<string>({
      url: 'blade-system/tenant/tenantId/domain',
      params: { domain },
      isNeedLogin: false
    });
  },
  // 获取用户所属租户的产品返回
  getGoodsRange () {
    return APIRequest.get<string>({
      url: 'blade-system/tenant/getTenantPattern',
      isNeedLogin: true
    });
  },
  // 网站上传文件
  globalUpload (params: FormData, uploadProgress?: (event: AxiosProgressEvent) => void) {
    return APIRequest.post<string>({
      url: 'blade-aliyun-oss/bladeOss/fileUpload',
      data: params,
      retry: 0,
      timeout: 1000 * 60 * 5,
      onUploadProgress: uploadProgress,
    });
  },
  // 获取公司介绍信息
  getCompanyIntroductionMap (tenantId: string) {
    return APIRequest.get<CompanyResultType>({
      url: 'blade-vstec/excompanyintroduction/getCompanyIntroductionByTenantId',
      params: { tenantId },
      isNeedLogin: false
    });
  },
  // 业务字典
  getDictionary (code: string) {
    return APIRequest.get<Dictionary[]>({
      url: '/blade-system/dict-biz/dictionary',
      params: { code },
      isNeedLogin: false
    });
  },
  // 获取当前代理商名字
  getMyTenantName (tenantId: string) {
    return APIRequest.get<string>({
      url: 'blade-user/tenant/getTenantNameByTenantId',
      params: { tenantId },
      isNeedLogin: false
    });
  },
  // 获取活动列表
  getActivityList (tenantId: string) {
    return APIRequest.get<ActivityModel[]>({
      url: 'blade-vstec/acactivitycenterconfig/getActivityByTenantId',
      params: { tenantId },
      isNeedLogin: false
    });
  },
  // 领取活动优惠券
  receiveActivityCoupon (id: string) {
    return APIRequest.post<boolean>({
      url: '/blade-vstec/acactivitycenterconfig/receiveCoupon',
      params: { id }
    });
  },
};

export default API_GLOBAL;
