// 正则表达式

// 判断16进制颜色
export const REG_HEX_COLOR = /^#?[0-9a-fA-F]{3}|#?[0-9a-fA-F]{6}$/;

// 捕获两位[0-9a-f]
export const REG_MATCH_HEX = /\B(?=([0-9a-f]{2})+(?![0-9a-f]))/g;

// 验证可以包含中文/字母(大小写)/数字
export const REG_CN_EN_NUM_F = (min: number, max: number) => {
  const reg = `^[\\u4e00-\\u9fa5A-Za-z0-9]{${min},${max}}$`;
  return new RegExp(reg);
};

// 验证字符串必须包含字母及数字
export const REG_INCL_EN_NUM_F = (min: number, max: number) => {
  const reg = `^(?=.*?[a-z])(?=.*?[0-9]).{${min},${max}}$`;
  return new RegExp(reg);
};

// 验证手机号
export const REG_PHONE = /^1[3-9]\d{9}$/;

// 验证联系方式(固话+手机号)
// 010-88888888 | +86-010-88888888 | 88888888
// 10-13333333333 | +86-10-13333333333 | 13333333333
export const REG_CONTACT = /^(((\+\d{2}-)?(0\d{2,3}-)?\d{7,8})|((\+\d{2}-)?(\d{2,3}-)?([1][3-9]\d{9})))$/;

// 验证邮箱
export const REG_EMAIL = /^\w+((-\w+)|(\.\w+))*@\w+(\.\w{2,3}){1,3}$/;

// 验证邮箱 (包含中文)
export const REG_EMAIL_CN = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[\u4e00-\u9fa5a-zA-Z0-9-]+)*\.[\u4e00-\u9fa5a-zA-Z0-9]{2,6}$/;

// 验证身份证号
export const REG_ID = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

// 验证营业执照编号
export const REG_BIZ_LIC_ID = /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/;

// 统一社会信用代码
export const REG_CREDIT_CODE = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/;

// 验证以a-z0-9开头结尾 中间以横线连接的字符串
export const REG_HORIZONTAL_LINE_URL = /^[a-z0-9]+([-][a-z0-9]+)*$/i;