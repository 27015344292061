import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta, individualBaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/router-generate';

const api = () => import('~/api/product/encology-colud/contract-lock');
const constant = () => import('~/views/goods/ecology-cloud/contract-lock/constant');
const tableConfig = () => import('~/views/goods/ecology-cloud/contract-lock/table.config');

// 契约锁中大型组织电子签章工具
export const purchase: RouteRecordRaw[] = [
  // 契约锁购买页面
  {
    path: 'ContractLock',
    name: 'contract-lock',
    component: () => import('~/views/goods/ecology-cloud/contract-lock/Purchase.vue'),
    meta: purchaseMeta
  },
];
// 契约锁订单
export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'ContractLock',
  name: 'contract-lock-order-list',
  meta: individualBaseMeta,
  menuProps: { name: '契约锁', show: true },
  component: 'order',
  props: {
    recordsApi: { path: api, property: 'getContractLockList' },
    jsonLabel: { path: constant, property: 'orderDetailJsonConfig' },
    paymentRoute: row => {
      const { ptId, orderId, orderNum, type } = row;
      return `/ContractLock?ptId=${ptId}&orderId=${orderId}&orderNum=${orderNum}&type=${type}`;
    },
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '生态云' },
        { text: '契约锁' }
      ]
    },
  }
});

export const renewal: RouteRecordRaw = createGoodsBusinessRoute<'renewal'>({
  path: 'ContractLock',
  name: 'contract-lock-renew-list',
  meta: individualBaseMeta,
  menuProps: { name: '契约锁', show: true },
  component: 'renewal',
  props: {
    recordsApi: { path: api, property: 'getContractLockInstanceList' },
    columns: { path: tableConfig, property: 'renewalColumns' },
    navigator: {
      list: [
        { text: '续费管理' },
        { text: '生态云' },
        { text: '契约锁' }
      ]
    },
  }
});

