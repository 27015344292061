import { proxyEnum } from '~/utils/enum-util';

/* 生态云订单的productType */
enum GoodsType {
  // 云盒
  CLOUD_BOX = 1,
  // Avaya话机
  AVAYA = 2,
  // 存储
  CLOUD_DISK_STORE = 3,
  // 带宽
  CLOUD_DISK_Flow = 4,
  // 无影
  SHADOWLESS = 5,
  // 灾备云
  DISASTER_CLOUD = 11,
  // 阿里云
  ALI_CLOUD = 8,
  // 云存储
  YCC = 10,
  // 优惠券
  YHJ = 14,
  // 安恒云 数据库审计
  AH_DATAAU = 15,
  // 安恒云 玄武盾云防护
  AH_XUANWU = 16,
  // 安恒云 堡垒机
  AH_BLJ = 17,
  // 安恒云 网站监测
  AH_WZJC = 18,
  // 安恒云 漏洞扫描
  AH_LDSM = 19,
  // 安恒云 主机安全
  AH_ZJAQ = 20,
  // 安恒云 防火墙
  AH_FHQ = 21,
  // 安恒云 攻击预警
  AH_GJYJ = 22,
  // 安恒云 等保
  AH_DB = 23,
  // 安恒云 日志审计
  AH_RZSJ = 24,
  // 服务管理ITSM
  CLOUD_WISE_ITSM = 29,
  // 监控宝
  CLOUD_WISE_ITPM = 30,
  // 透视宝
  CLOUD_WISE_APM = 31,
  // 透视宝服务
  CLOUD_WISE_APM_SERVICE = 32,
  /* CloudCCCRM */
  CLOUD_CC_CRM = 33,
  // avayacc
  AVAYA_CC = 34,
  /* 云梦建站 */
  // 云·企业官网
  CLOUD_STATION = 26,
  // 云·速成美站
  CLOUD_DREAM = 27,

  // 天威
  V_CERT_CLOUD = 4001,
  // 智能云客服系统
  HOLLY_CRM = 28,
  // 联蔚
  LianWei = 25,
  // 数据安全防护
  DATA_PROTECTION = 35,
  // 葡萄城
  GRAPE_CITY = 37,
  // 蓝凌
  LANDARY = 39,
  // e签宝
  E_SIGN = 36,
  // 思通NLP
  STONE_NLP = 40,
  // 契约锁
  CONTRACT_LOCK = 41,
}

/* 优惠方式 */
enum preferentialWay {
  /* 余额 */
  BALANCE,
  /* 代金券 */
  VOUCHER,
  /* 优惠券 */
  COUPON
}

/* 支付方式 */
enum paymentMethod {
  BALANCE, /* 余额 */
  STORED_VALUE/* 储值卡 */
}

/* 生态云订单类型 */
const EcologyCloudOrderDic: Record<number, { label: string, value: number }> = {
  0: { label: '新购', value: 0 },
  1: { label: '续费', value: 1 },
  2: { label: '扩容', value: 2 }
};

/* 产品隶属分类 */
enum GoodsCate {
  // 阿里云
  ALI,
  // 生态云
  ECOLOGY,
  // 百度云
  BAIDU = 3,
  // 移动云
  Mobile
}

// 固定ptId储值卡查询
enum ConstStoredCard {
  // 阿里云
  ALi = 183,
  // 百度云
  BAIDU = 185
}

// 基本订单状态
const BaseOrderStatus = proxyEnum({
  UNPAID: { label: '未支付', value: 0 },
  PAID: { label: '已支付', value: 1 },
  CANCELED: { label: '已取消', value: 2 },
} as const);

// 基本订单类型
const BaseOrderType = proxyEnum({
  NEW: { label: '新购', value: 0 },
  RENEWAL: { label: '续费', value: 1 },
  EXPANSION: { label: '扩容', value: 2 },
} as const);

// 基本订单支付状态
const BaseOrderPayStatus = proxyEnum({
  Paid: { label: '已支付', value: 1 },
  unPaid: { label: '未支付', value: 0 },
} as const);
export {
  GoodsType,
  preferentialWay,
  paymentMethod,
  GoodsCate,
  EcologyCloudOrderDic,
  ConstStoredCard,
  BaseOrderStatus,
  BaseOrderType,
  BaseOrderPayStatus
};