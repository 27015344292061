import { APIRequest } from '~/api/config';
import { PersonalListSearchParams } from '~/models/base-types';
import { CashCoupon } from '~/models/product/cash-coupon';
import {
  GoodsList,
  Dtinstance,
  GoodsDetail,
  GoodsItem,
  GoodsType,
  OrderFinallyPrice,
  OrderUntilPrice,
  TenantGoodsTypeItem,
  UAgreementListModel
} from '~/models/product/goods-list';

const API_GOODS = {
  // 通用获取产品的购买实例列表
  getGoodsExample (params: PersonalListSearchParams, productType?: number) {
    const data = productType ? Object.assign(params, { productType: `${productType}` }) : params;
    return APIRequest.get<Dtinstance>({ url: 'blade-vstec/dtinstance/page', params: data });
  },
  // 获取产品的代金券
  getGoodsCash (pt: number) {
    return APIRequest.get<CashCoupon[]>({ url: 'blade-vstec/dtcashcoupon/getUserCanUseCashCoupon', params: { ptId: pt } });
  },
  // 根据orderId获取订单的详情
  getGoodsOrderDetail (orderId: string) {
    return APIRequest.get<GoodsDetail>({ url: `blade-vstec/dtorder/${orderId}` });
  },
  // 获取所有的产品分类
  getGoodsType () {
    return APIRequest.get<GoodsType[]>({ url: 'blade-vstec/ptproductscate/getListByTree', params: { status: 0 }, isNeedLogin: false });
  },
  // 根据分类id获取产品
  getGoodsForTypeId (cateId: number) {
    return APIRequest.get<GoodsList>({
      url: 'blade-vstec/ptproduct/getListByCateId',
      params: { current: 1, size: 999, cateId },
      isNeedLogin: false
    });
  },
  // 获取当前租户下的分类id
  getGoodsTypeForTenant (tenant: string) {
    return APIRequest.get<TenantGoodsTypeItem[]>({
      url: 'blade-vstec/ptpctrel/getRelListByTenantId',
      params: { tenantId: tenant },
      isNeedLogin: false
    });
  },
  // 根据id获取产品详情
  getGoodsInfoForId (id: number) {
    return APIRequest.get<GoodsItem>({ url: 'blade-vstec/ptproduct/getListById', params: { id }, isNeedLogin: false });
  },
  // 根据关键字获取产品
  getGoodsForKeyWord (key: string) {
    return APIRequest.get<GoodsList>({
      url: 'blade-vstec/ptproduct/getListByPage',
      params: { title: key, current: 1, size: 99 },
      isNeedLogin: false
    });
  },
  // 根据租户id获取当前产品关联模板
  getTenantGoods (tenant: string) {
    return APIRequest.get<{ productIds: string }>({
      url: 'blade-vstec/ptpdtrel/getRelListByTenantId',
      params: { tenantId: tenant },
      isNeedLogin: false
    });
  },
  // 获取全部产品
  getAllGoods () {
    return APIRequest.get<GoodsItem[]>({ url: 'blade-vstec/ptproduct/getList', isNeedLogin: false });
  },
  // 获取产品详情
  getGoodsInfoForIdArr (idArr: number[]) {
    return APIRequest.post<GoodsItem[]>({ url: 'blade-vstec/ptproduct/getOnlyDetail', isNeedLogin: false, data: idArr });
  },
  // 获取未支付订单的支付金额
  getNotPayOrderPrice (params: OrderUntilPrice) {
    return APIRequest.get<string>({ url: 'blade-vstec/dtorder/amountPayable', params });
  },
  // 根据orderId获取订单价格
  getUntilOrderPrice (orderId: string) {
    return APIRequest.get<OrderFinallyPrice>({ url: 'blade-vstec/dtorder/calculateAfterHypPrice', params: { orderId } });
  },
  // 根据ptid获取产品协议
  getUAgreementListByPtId (ptId: string) {
    return APIRequest.get<UAgreementListModel[]>({ url: 'blade-vstec/uagreement/getUAgreementListByPtId', params: { ptId } });
  }
};
export default API_GOODS;
