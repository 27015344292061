import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta, individualBaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/router-generate';

const api = () => import('~/api/product/encology-colud/cloud-cc-crm');
const constant = () => import('~/views/goods/ecology-cloud/cloud-cc-crm/constant');
const instanceApi = () => import('~/api/product');
const tableConfig = () => import('~/views/goods/ecology-cloud/cloud-cc-crm/table.config');

// CloudCC CRM 购买
export const purchase: RouteRecordRaw[] = [
  // 新购
  {
    path: 'CloudCCCRM',
    name: 'cloud-cc-crm-purchase',
    component: () => import('~/views/goods/ecology-cloud/cloud-cc-crm/Purchase.vue'),
    meta: purchaseMeta
  },
  // 升配/续费
  {
    path: 'CloudCCCRMRenew',
    name: 'cloud-cc-crm-renew',
    component: () => import('~/views/goods/ecology-cloud/cloud-cc-crm/Renew.vue'),
    meta: purchaseMeta
  }
];

// CloudCC CRM 订单列表
export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'CloudCCCRM',
  name: 'cloud-cc-crm-order-list',
  meta: individualBaseMeta,
  menuProps: { name: 'CloudCC CRM', show: true },
  component: 'order',
  props: {
    recordsApi: { path: api, property: 'getCloudCCOrderList' },
    jsonLabel: { path: constant, property: 'DetailLabelConfig' },
    paymentRoute: row => {
      const { ptId, orderId, orderNum } = row;
      return `/CloudCCCRM?ptId=${ptId}&orderId=${orderId}&orderNum=${orderNum}`;
    },
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '生态云' },
        { text: 'CloudCC CRM' }
      ]
    },
  }
});

// CloudCC CRM 续费列表
export const renewal: RouteRecordRaw = createGoodsBusinessRoute<'renewal'>({
  path: 'CloudCCCRM',
  name: 'cloud-cc-crm-renew-list',
  meta: individualBaseMeta,
  menuProps: { name: 'CloudCC CRM', show: true },
  component: 'renewal',
  props: {
    recordsApi: { path: instanceApi, property: 'getGoodsExample' },
    columns: { path: tableConfig, property: 'instanceColumns' },
    navigator: {
      list: [
        { text: '续费管理' },
        { text: '生态云' },
        { text: 'CloudCC CRM' }
      ]
    },
  }
});