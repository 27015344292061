import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta, individualBaseMeta, consoleBaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/router-generate';

const api = () => import('~/api/product/encology-colud/landary-cloud');
const constant = () => import('~/views/goods/ecology-cloud/landary-cloud/constant');


// 蓝凌
export const purchase: RouteRecordRaw[] = [
  // 蓝凌购买页面
  {
    path: 'Landary',
    name: 'landary-cloud',
    component: () => import('~/views/goods/ecology-cloud/landary-cloud/Purchase.vue'),
    meta: purchaseMeta
  },
];

export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'Landary',
  name: 'landary-cloud-order-list',
  meta: individualBaseMeta,
  menuProps: { name: '蓝凌业务经营云', show: true },
  component: 'order',
  props: {
    recordsApi: { path: api, property: 'getLandaryOrderList' },
    jsonLabel: { path: constant, property: 'orderDetailJsonConfig' },
    paymentRoute: row => {
      const { ptId, orderId, orderNum } = row;
      return `/Landary?ptId=${ptId}&orderId=${orderId}&orderNum=${orderNum}`;
    },
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '生态云' },
        { text: '蓝凌业务经营云' }
      ]
    }
  }
});

// 蓝凌控制台  认证
export const consolePage: RouteRecordRaw[] = [
  {
    path: 'Landary',
    name: 'landary-console',
    component: () => import('~/views/goods/ecology-cloud/landary-cloud/Console.vue'),
    meta: consoleBaseMeta,
  },
  {
    path: 'LandaryAuthPage',
    name: 'landary-auth-page',
    component: () => import('~/views/goods/ecology-cloud/landary-cloud/LandaryAuthPage.vue'),
    meta: consoleBaseMeta,
  }
];

