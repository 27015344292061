import { APIRequest } from '~/api/config';
import qs from 'qs';
import {
  AuthenticationForm,
  User,
  UserDiscountList,
  UserToken,
  Login,
  UserLoginParams,
  SaveUserInfoParams,
  UpdatePasswordParams
} from '~/models/user';
import { CurrentParams } from '~/models/response-type';
import MD5 from 'js-md5';
import { ElMessage } from 'element-plus';

// 安全管理 接口返回处理
const safetyApiResHandle = (response: string) => {
  if (response === 'OK') {
    return true;
  }
  if (Object.keys(response).length) {
    ElMessage.warning(response);
  }
  return false;
};

const API_USER = {
  // 发送验证码
  sendMessage (phone: string) {
    return APIRequest.get<boolean>({
      url: `blade-aliyun-mq-service/sms/sendRegisterCode?phone=${phone}`,
      isNeedLogin: false
    });
  },
  // 注册
  registerUser (loginObj: Login) {
    return APIRequest.post({
      url: 'blade-user/user/registerUser',
      data: loginObj,
      isNeedLogin: false
    });
  },
  // 登录
  LoginUser (userForm: UserLoginParams) {
    const { username, password, verification } = userForm;
    userForm.password = `${MD5(password)}`;
    const headers = {
      'Authorization': 'Basic c3dvcmQ6c3dvcmRfc2VjcmV0',
      'Content-Type': 'application/x-www-form-urlencoded',
      'origin_from': '2',
      'account': encodeURIComponent(username),
      'verification': verification || ''
    };
    if (!verification) {
      Reflect.deleteProperty(headers, 'verification');
    }
    return APIRequest.post<UserToken>({
      url: 'blade-auth/oauth/token',
      data: qs.stringify(userForm),
      headers,
      isNeedLogin: false,
      needDeepData: false,
      notReport: true
    });
  },
  // 注销
  cancellationUser () {
    return APIRequest.get<boolean>({ url: 'blade-auth/oauth/logout' });
  },
  // 获取用户信息
  getUserInfo () {
    return APIRequest.get<User>({ url: 'blade-user/getUserInfo', retry: 2, timeout: 20000 });
  },
  // 获取用户折扣
  getUserDiscount (params: CurrentParams) {
    return APIRequest.get<UserDiscountList>({
      url: `blade-vstec/udtexpand/getUserSelfDiscountAll?${qs.stringify(params)}`
    });
  },
  // 用户忘记密码获取手机号
  getPhoneForForget (loginAccount: string, tenantId: string) {
    return APIRequest.get<string>({
      url: 'blade-user/forget/getPhone',
      params: { account: loginAccount, tenantId, originFrom: '2' },
      isNeedLogin: false
    });
  },
  // 忘记密码获取验证码
  getPhoneCodeForget (account: string) {
    return APIRequest.get<boolean>({
      url: 'blade-aliyun-mq-service/sms/sendForgetCode',
      params: { account },
      isNeedLogin: false
    });
  },
  // 验证忘记密码的手机验证码
  checkForgetPhoneCode (account: string, code: string) {
    return APIRequest.post<boolean>({
      url: 'blade-user/forget/forgetPassword',
      data: qs.stringify({ account, code }),
      isNeedLogin: false
    });
  },
  // 开始修改忘记密码的密码
  updateForgetPassword (password: string, account: string, tenantId: string) {
    return APIRequest.post<boolean>({
      url: 'blade-user/forget/updatePwd',
      data: qs.stringify({ password, account, tenantId }),
      isNeedLogin: false
    });
  },
  // 实名认证
  authenticationUser (authenticationData: AuthenticationForm) {
    return APIRequest.post<boolean>({ url: 'blade-user/realNameAuthentication', data: authenticationData });
  },
  // 修改用户信息
  saveUserInfo (data: SaveUserInfoParams) {
    return APIRequest.post<boolean>({ url: 'blade-user/update-info', data });
  },
  // 获取用户余额
  getUserBalance () {
    return APIRequest.get<string>({ url: 'blade-vstec/uuserbalance/getUserBalance' });
  },
  // 获取用户近半年产品消费
  getHalfYearBuy () {
    return APIRequest.get<string[]>({ url: 'blade-vstec/statistics/buyProductsByUser' });
  },
  // 首次登录or异地登录获取验证码
  getPhoneByVerification (account: string) {
    return APIRequest.get<boolean>({
      url: '/blade-user/user/getPhoneByVerification',
      params: { account },
      isNeedLogin: false
    });
  },
  // 安全管理 身份验证 发送验证码
  async sendAuthVerifyCode () {
    const res = await APIRequest.get<string>({ url: '/blade-aliyun-mq-service/sms/sendUpdatePwdCode' });
    return safetyApiResHandle(res);
  },
  // 安全管理 身份验证 验证短信验证码
  verifyAuthMessageCode (code: string) {
    return APIRequest.get<string>({ url: '/blade-user/updatePwdByCode', params: { code } });
  },
  // 安全管理 修改密码
  updateUserPassword (params: UpdatePasswordParams) {
    return APIRequest.post<string>({ url: '/blade-user/update-password', params });
  },
  // 安全管理 换绑手机 发送验证码
  async sendNewPhoneVerifyCode (phone: string) {
    const res = await APIRequest.get<string>({ url: '/blade-aliyun-mq-service/sms/sendNewPhoneCode', params: { phone } });
    return safetyApiResHandle(res);
  },
  // 安全管理 校验换绑手机 短信验证码
  checkNewPhoneVerfiyCode (params: { phone: string, code: string }) {
    return APIRequest.get<string>({ url: '/blade-user/checkCodeByPhone', params });
  },
  // 安全管理 换绑邮箱 发送验证码
  async sendNewEmailVerifyCode (email: string) {
    const res = await APIRequest.get<string>({ url: '/blade-aliyun-mq-service/sms/sendNewEmailCode', params: { email } });
    return safetyApiResHandle(res);
  },
  // 安全管理 校验换绑邮箱 验证码
  checkNewEmailVerifyCode (params: { email: string, code: string }) {
    return APIRequest.get<string>({ url: '/blade-user/checkCodeByEmail', params });
  },
  // 安全管理 修改手机号/邮箱
  updateUserPhoneOrEmail (params: { phone: string } | { email: string }) {
    return APIRequest.update<boolean>({ url: '/blade-user/updatePhoneOrEmail', params });
  },
};

export default API_USER;