// 可复用路由

import { RouteRecordRaw } from 'vue-router';
import { multipleRoutesGoods } from '~/router/goods-routes';

// 百度
const baiduBlackList = [
  // 百度云产品拦截
  '/BaiduPurchase',
  // 百度云支付
  '/BaiduSettlement',
  // 百度控制台
  '/Console/BaiduCloud',
  // 百度云域名控制台
  '/Console/BaiduDomain',
];
// 阿里
const aliBlackList = [
  // 阿里云产品拦截
  '/AliPurchase',
  // 阿里云控制台
  '/Console/AliCloud',
];

/**
 *@description: 以下集合作为判断使用
 *企业基本信息认证黑名单路由: 基本信息认证没通过，就不能访问的路由
 *框架协议认证黑名单路由: 就算基本信息通过，但是框架协议没有通过就不能访问的路由
 *产品认证黑名单路由: 基本信息认证没通过，框架协议通过，但是有产品指定的认证没有通过就不能访问的路由
*/
// 企业基本信息认证黑名单路由
const baseInfoBlacklistRoute: string[] = [];
// 递归获取路由path
const recursionRoutesHanlder = (route: RouteRecordRaw, parentPath?: string) => {
  const { children, path, meta } = route;
  const emitpyParentPath = path[0] !== '/' ? '/' : '';
  const parent = parentPath || emitpyParentPath;
  const needLogin = meta?.requireAuth;
  if ((!children || children?.length === 0) && needLogin) {
    baseInfoBlacklistRoute.push(parent + path);
  }
  if (children && children.length > 0) {
    for (let i = 0; i < children.length; i++) {
      const childrenRoute = children[i];
      recursionRoutesHanlder(childrenRoute, `${parent + path}/`);
    }
  }
};
// 获取生态云产品购买、控制台
const ecologyCloudBlacklistRoute = multipleRoutesGoods.get('ecology-cloud')?.route;
if (ecologyCloudBlacklistRoute) {
  for (const [key, value] of Object.entries(ecologyCloudBlacklistRoute)) {
    if (['consolePage', 'purchase'].includes(key) && Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        recursionRoutesHanlder(value[i], key === 'consolePage' ? '/Console/' : '');
      }
    }
  }
}
// 框架协议认证黑名单路由
const empowerBlacklistRoute = [
  '/Individual/Invoice/Index',
  ...baiduBlackList,
  ...aliBlackList,
];

// 产品认证黑名单路由
const productBlackListRoute = [
  // 生态云 - 蓝凌业务经营云
  '/Console/Landary',
  // 生态云 - e签宝
  '/ESign',
  ...baiduBlackList,
  ...aliBlackList,
];

// 登录后不能进行跳转的页面
const loginBlackListRoute = [
  '/Login',
  '/Register',
  '/Forget'
];

// reseller白名单
const resellerWhitelistRoute = [
  // 个人中心主页
  '/Individual/Index',
  // 合同
  '/Individual/Contract',
  // 发票概览
  '/Individual/Invoice/Index',
  // 去开票
  '/Individual/Invoice/Invoicing',
  // 开票信息
  '/Individual/Invoice/Info',
  // 开票记录
  '/Individual/Invoice/Record',
  // 工单列表
  '/Individual/WorkOrder/List',
  // 工单详情
  '/Individual/WorkOrder/Detail',
  // 地址管理
  '/Individual/Address',
  // 安全管理
  '/Individual/Safety/Index',
  '/Individual/Safety/Authentication',

];
export {
  baseInfoBlacklistRoute,
  empowerBlacklistRoute,
  loginBlackListRoute,
  productBlackListRoute,
  resellerWhitelistRoute
};