import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta } from '~/router/router-meta';

export const purchase: RouteRecordRaw[] = [
  {
    path: 'DuanMu',
    name: 'duan-mu',
    component: () => import('~/views/goods/ecology-cloud/duan-mu/index.vue'),
    meta: purchaseMeta
  }
];

