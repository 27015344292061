import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta, individualBaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/router-generate';

const api = () => import('~/api/product/encology-colud/decimal-system-disaster');
const constant = () => import('~/views/goods/ecology-cloud/decimal-system-disaster/constant');
const instanceApi = () => import('~/api/product');
const tableConfig = () => import('~/views/goods/ecology-cloud/decimal-system-disaster/table.config');

// 壹进制灾备云 购买
export const purchase: RouteRecordRaw[] = [
  // 新购
  {
    path: 'DecimalSystemDisaster',
    name: 'decimal-system-disaster-purchase',
    component: () => import('~/views/goods/ecology-cloud/decimal-system-disaster/Purchase.vue'),
    meta: purchaseMeta
  },
  // 续费
  {
    path: 'DecimalSystemDisasterRenew/:instance',
    name: 'decimal-system-disaster-renew',
    component: () => import('~/views/goods/ecology-cloud/decimal-system-disaster/Renew.vue'),
    meta: purchaseMeta
  },
  // 升配
  {
    path: 'DecimalSystemDisasterExpansion/:instance',
    name: 'decimal-system-disaster-expansion',
    component: () => import('~/views/goods/ecology-cloud/decimal-system-disaster/Expansion.vue'),
    meta: purchaseMeta
  },
  /* 结算 */
  createGoodsBusinessRoute<'old_settlement'>({
    path: 'DecimalSystemDisaster/Settlement/:orderId',
    name: 'decimal-system-disaster-settlement',
    meta: purchaseMeta,
    component: 'old_settlement',
    props: {
      orderRoute: '/Individual/Order/EcologyCloud/DecimalSystemDisaster',
      paymentApi: { path: api, property: 'payDisasterOrder' },
      orderDetailJsonConfig: { path: constant, property: 'orderDetailProductConfig' },
    }
  })
];

// 壹进制灾备云 订单列表
export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'DecimalSystemDisaster',
  name: 'decimal-system-disaster-order-list',
  meta: individualBaseMeta,
  menuProps: { name: '壹进制灾备云', show: true },
  component: 'order',
  props: {
    recordsApi: { path: api, property: 'getDisasterOrderList' },
    jsonLabel: { path: constant, property: 'orderDetailProductConfig' },
    paymentRoute: ({ orderId }) => `/DecimalSystemDisaster/Settlement/${orderId}`,
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '生态云' },
        { text: '壹进制灾备云' }
      ]
    },
  }
});

// 壹进制灾备云 续费列表
export const renewal: RouteRecordRaw = createGoodsBusinessRoute<'renewal'>({
  path: 'DecimalSystemDisaster',
  name: 'decimal-system-disaster-renew-list',
  meta: individualBaseMeta,
  menuProps: { name: '壹进制灾备云', show: true },
  component: 'renewal',
  props: {
    filterTable: { cache: true },
    recordsApi: { path: instanceApi, property: 'getGoodsExample' },
    columns: { path: tableConfig, property: 'renewalColumns' },
    navigator: {
      list: [
        { text: '续费管理' },
        { text: '生态云' },
        { text: '壹进制灾备云' }
      ]
    },
  }
});
